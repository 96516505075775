import Link from "next/link";
import {AnimatePresence, motion} from "framer-motion";
import {ArrowSmallRightIcon, InformationCircleIcon, TicketIcon, ArrowPathIcon} from "@heroicons/react/24/outline";
import {formatDate, getDateDay, getDateMonth, slugify} from "@/lib/util";
import {getParkFromParkCode} from "@/lib/data";
import Image from "next/image";
import clsx from "clsx";
import SmallInfo from "@/components/SmallInfo";

export default function EventItem({ event, isListView }) {

    // console.log('event',event);

    let category = event && event.category,
        dates = event && event.dates,
        dateStart = event && event.dateStart,
        dateEnd = event && event.dateEnd,
        description = event && event.description,
        feeInfo = event && event.feeInfo,
        registerUrl = event && event.regresUrl,
        infoUrl = event && event.infoUrl,
        firstImage = event?.images?.[0]?.url ? `https://www.nps.gov${event?.images?.[0]?.url}` : null,
        //eventUrl = registerUrl ? registerUrl : infoUrl,
        eventUrl = event && `/event/${event.slug}`,
        recurrenceDateStart = event && event.recurrenceDateStart,
        recurrenceDateEnd = event && event.recurrenceDateEnd,
        parkFullName = event && event.parkFullName,
        times = event && event.times,
        timeEnd = times?.timeend,
        timeStart = times?.timestart,
        title = event && event.title,
        types = event && event.types,
        isRegistrationRequired = event && event.isRegresRequired === 'true',
        isRecurring = event && event.isRecurring,
        parkCode = event && event.parkCode,
        park = getParkFromParkCode(parkCode),
        parkImage = park[0]?.images?.length ? park[0].images[0].url : null,
        image = firstImage || parkImage;

    // console.log('image',image)

    return (
         <>
             {event && (
                 <AnimatePresence>
                     <motion.article
                         initial={{ opacity: 0, y: -5 }}
                         animate={{ opacity: 1, y: 0 }}
                         exit={{ opacity: 0, y: -5 }}
                         transition={{ bounce: 0, duration: 0.2 }}
                         key={event.id}
                         className="bg-white h-full rounded border border-brown-100/10 overflow-hidden text-brown-100 text-sm transition shadow-sm hover:shadow-md"
                     >
                         <div className={`flex justify-between h-full ${isListView ? 'flex-row' : 'flex-col gap-0'}`}>
                         <div className={`flex flex-col ${isListView ? 'md:w-4/6' : 'w-full'}`}>
                             {/*  py-4 px-4 md:py-5 md:px-5 */}
                             <div className={`flex ${isListView ? '' : 'flex-col'}`}>
                                 <div className={`${isListView ? 'h-full w-[300px]' : 'w-full h-[200px]'}`}>
                                     <div className="relative h-full block">
                                         <time dateTime={dateStart} className={`absolute top-0 left-0 w-[55px] h-fit py-1 bg-green-100 text-white font-serif text-center mr-3 z-1 rounded-br-xs`}>
                                             {getDateMonth(dateStart)}
                                             <span className="block text-xl md:text-2xl -mt-2">{getDateDay(dateStart)}</span>
                                             {/*{times.timestart ?*/}
                                             {/*    <span className="font-sans text-xs">*/}
                                             {/*        {times.timestart}*/}
                                             {/*    </span>*/}
                                             {/*    : null}*/}
                                         </time>
                                         <p className={`text-xxs font-bold absolute bottom-0 z-1 bg-white py-1 px-3 text-brown-100/70
                                         ${isListView ? 'right-0 rounded-tl-xs' : 'left-0 rounded-tr-xs'}`}>
                                             {category}
                                         </p>
                                         {image &&
                                             <Image src={image} alt={parkFullName} width={500} height={500} className={`object-cover w-full h-full z-0 aspect-square ${isListView ? 'absolute top-0' : ''}`} />
                                         }
                                     </div>
                                 </div>

                                 <div className={`basis-full ${isListView ? 'md:py-5 md:px-5' : 'p-5'}`}>
                                     <Link href={`/park/${slugify(parkFullName)}`} className="text-xxs uppercase font-black text-brown-100/80">
                                         {parkFullName}
                                     </Link>
                                     <h2 className="text-lg font-normal leading-tight text-green-100 md:text-2xl my-0 mt-2 w-full">
                                         {eventUrl ?
                                             <a href={`${eventUrl}`} target="_blank" rel="noreferrer">
                                                 {title}
                                             </a>
                                             : title}

                                     </h2>
                                     {isRegistrationRequired ? <h2 className="font-sans text-xs mt-2">Registration Required</h2> : null}

                                     {registerUrl || infoUrl ?
                                         <div className="mt-4 text-xs">
                                             {infoUrl ?
                                                 <a href={infoUrl} target="_blank" rel="noreferrer" className="flex">
                                                     <InformationCircleIcon width={20} className="mr-2" /> More information
                                                 </a>
                                                 : null}
                                             {registerUrl ?
                                                 <>
                                                     <a href={registerUrl} target="_blank" rel="noreferrer" className="flex">
                                                         <TicketIcon width={20} className="mr-2" /> Buy tickets
                                                     </a>
                                                 </>
                                                 : null}
                                         </div>
                                     : null}

                                     {types ?
                                         <ul className={`flex flex-wrap mt-4`}>
                                             {types.map((type, i) =>
                                                 <li key={`${type}-${i}`}>
                                                     <p className={`text-xxs my-1 py-1 pb-1 px-3 bg-beige-200 rounded-full mr-2`}>{type}</p>
                                                 </li>
                                             )}
                                         </ul>
                                         : null}

                                     {description && description.length > 120 ?
                                         <div dangerouslySetInnerHTML={{ __html: description.slice(0, 120) + `... <a href=${eventUrl}>Read more</a>` }} className="mt-2 text-xs"></div>
                                         :
                                         <div dangerouslySetInnerHTML={{ __html: description}} className="mt-2 text-xs"></div>
                                     }

                                 </div>
                             </div>
                         </div>
                         <div className={`${isListView ? 'gap-4 flex flex-row justify-between w-2/6 border-l border-brown-100/10' : 'p-5 border-t border-brown-100/10'}`}>
                           <div className={`flex flex-col gap-4 ${isListView ? 'md:py-5 md:px-5 w-full' : ''}`}>
                             {isRecurring ?
                                 <div className={`flex uppercase text-xxs font-bold items-center text-brown-100/60`}>
                                     <ArrowPathIcon width="18" className="mr-2" /> Repeating Event
                                 </div>
                                 : null}
                             {recurrenceDateStart || recurrenceDateEnd ?
                                 <div>
                                     <h2 className="uppercase text-xxs font-sans font-black text-green-100 mb-1">Date(s)</h2>
                                     <p className="text-xs">
                                         {recurrenceDateStart ? formatDate(recurrenceDateStart) : null}
                                         {recurrenceDateStart !== '' && recurrenceDateEnd !== '' ? ' - ' : null}
                                         {recurrenceDateEnd ? formatDate(recurrenceDateEnd) : null}
                                     </p>
                                 </div> : null}
                             {feeInfo ? <SmallInfo title="Fees">{feeInfo}</SmallInfo> : null}
                             {timeStart || timeEnd ?
                                 <div>
                                     <h2 className="uppercase text-xxs font-sans font-black text-green-100 mb-1">Time</h2>
                                     <p className="text-xs">
                                         {timeStart ? timeStart : null}
                                         {timeStart && timeEnd ? ' - ' : null}
                                         {timeEnd ? timeEnd : null}
                                     </p>
                                 </div> : null}
                           </div>
                             {eventUrl && isListView ?
                                 <a className={`flex self-center justify-center px-3 bg-beige-200 text-brown-100/70 ${isListView ? 'w-20 h-full' : 'w-full h-10'}`} href={`${eventUrl}`} target="_blank" rel="noreferrer">
                                     <span className="sr-only">View event</span>
                                     <ArrowSmallRightIcon width={20}/>
                                 </a>
                                 : null}
                         </div>
                         {eventUrl && !isListView ?
                             <a className={`flex self-center justify-center px-3 bg-beige-200 text-brown-100/70 ${isListView ? 'w-20' : 'w-full h-10'}`} href={`${eventUrl}`} target="_blank" rel="noreferrer">
                                 <span className="sr-only">View event</span>
                                 <ArrowSmallRightIcon width={20}/>
                             </a>
                         : null}
                     </div>
                     </motion.article>
                 </AnimatePresence>
             )}
         </>
     )
}
function LoaderCardItem({ color, className }) {
    return (
        <div>
            <div className={`w-full h-[200px] mt-3 rounded animate-pulse bg-${color} ${className}`}></div>
            <div className={`w-full h-[15px] mt-3 rounded animate-pulse bg-${color} ${className}`}></div>
            <div className={`w-full h-[15px] mt-3 rounded animate-pulse bg-${color} ${className}`}></div>
            <div className={`w-full h-[15px] mt-3 rounded animate-pulse bg-${color} ${className}`}></div>
        </div>
    )
}

export default function LoaderCard({ color, className, gridItems }) {
    let classNames = className ? className : '',
        barColor = color ? color : 'beige-200',
        gridLayout = gridItems ? gridItems : 1;
    return (
       <>
           {gridLayout ?
               <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6`}>
                   {[...Array(gridItems)].map((e, i) => (<LoaderCardItem color={barColor} className={classNames} key={i} />))}
               </div>
           : <LoaderCardItem /> }
       </>
    )
}
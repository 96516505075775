import { usePagination, DOTS } from '@/hooks/usePagination';
import {ArrowLongLeftIcon, ArrowLongRightIcon} from '@heroicons/react/20/solid'
import clsx from "clsx";
import {scrollTop} from "@/lib/util";
import {useRouter} from "next/router";

export default function Pagination({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize = 10, scrollTopElement, className }) {
    const router = useRouter()

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const addPageQuery = (page) => {
        const pageVal = Number(page);
        const query = {
            ...router.query,
            page: pageVal,
        }
        router.replace({
            pathname: router.pathname,
            query,
        }, undefined, { shallow: true })
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
        scrollTopElement !== undefined && scrollTop(scrollTopElement)
        addPageQuery(currentPage + 1)
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
        scrollTopElement !== undefined && scrollTop(scrollTopElement)
        addPageQuery(currentPage - 1)
    };

    const onPageNumber = (pageNumber) => {
        onPageChange(pageNumber);
        scrollTopElement !== undefined && scrollTop(scrollTopElement)
        addPageQuery(pageNumber)
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <nav className={clsx("flex items-center justify-between border-t-2 border-brown-100/5 px-4 sm:px-0", { [className]: className } )}>
            <div className={clsx("-mt-px flex w-0 flex-1")}>
                <button className={clsx("inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-xs transition hover:opacity-70",
                    { ['hidden']:  currentPage === 1 } )}
                    onClick={onPrevious}
                >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="hidden md:block">Previous</span>
                </button>
            </div>
            <div className="-mt-px md:flex">
                {paginationRange.map((pageNumber, i) => {

                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        return <li key={`${i}-dots`} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 opacity-40">&#8230;</li>;
                    }

                    // Render our Page Pills
                    return (
                        <li
                            key={`${i}-pageNumber`}
                            className={clsx('inline-flex items-center border-t-2 px-4 pt-4 text-xs hover:cursor-pointer', {
                                ['text-green-100 border-green-100']: pageNumber === currentPage,
                                ['border-transparent']: pageNumber !== currentPage
                            })}
                            onClick={() => onPageNumber(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    className={clsx("inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-xs transition hover:opacity-70",
                    { ['hidden']: currentPage === lastPage} )}
                    onClick={onNext}
                >
                    <span className="hidden md:block">Next</span>
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </nav>
    );
};
export const selectStyles = {
    control: (baseStyles) => ({
        ...baseStyles,
        borderWidth: '0',
        boxShadow: 'none',
        borderRadius: '10px',
        height: '70px',
        backgroundColor: '#fff',
    }),
    menu: (styles) => ({
        ...styles,
        borderWidth: '0',
        borderRadius: '10px',
        backgroundColor: 'white'
    }),
    menuList: (styles) => ({
        ...styles,
        borderWidth: '0',
        borderRadius: '10px',
        textAlign: 'left'
    }),
    valueContainer: (styles) => ({
        ...styles,
        paddingLeft: '22px',
        fontSize: '17px',
        color: '#35150F',
        textAlign: 'left',
        padding: '1px 8px'
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        justifyContent: 'center',
        alignSelf: 'center',
        padding: '0 8px',
        height: '69%',
        marginRight: '15px',
        borderRadius: '10px',
        backgroundColor: '#35845A'
    }),
    indicatorContainer: (styles) => ({
        ...styles,
        color: 'hsl(0deg 0% 100%)',
        justifyContent: 'center',
        padding: '10px'
    }),
    clearIndicator: (styles) => ({
        ...styles,
        color: '#fff',
        borderRight: '2px solid hsl(148deg 46% 31%)',
        ':hover': {
            color: 'hsl(0deg 0% 100% / 80%)',
            cursor: 'pointer'
        }
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        width: '35px',
        height: '35px',
        color: '#fff',
        ':hover': {
            color: 'hsl(0deg 0% 100% / 80%)',
            cursor: 'pointer'
        }
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    singleValue: (styles) => ({
        ...styles,
        fontSize: '17px',
        color: '#35150F',
    }),
    option: (styles, {isFocused}) => ({
        borderWidth: '0',
        padding: '12px 16px 10px',
        fontSize: '15px',
        backgroundColor: isFocused ? '#35845A' : 'inherit',
        color: isFocused ? 'white' : '#35150F',
        ':hover': {
            backgroundColor: '#35845A',
            color: 'white',
            cursor: 'pointer'
        }
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#35150f85',
        fontSize: '17px'
    })
}

export const filterSelectStyles = {
    control: (baseStyles) => ({
        ...baseStyles,
        borderWidth: '0',
        boxShadow: 'none',
        borderRadius: '7px',
        height: '40px',
        backgroundColor: '#fff',
        border: '1px solid #eadfd0'
    }),
    menu: (styles) => ({
        ...styles,
        borderWidth: '0',
        borderRadius: '10px',
        backgroundColor: 'white'
    }),
    menuList: (styles) => ({
        ...styles,
        borderWidth: '0',
        borderRadius: '10px',
        textAlign: 'left'
    }),
    valueContainer: (styles) => ({
        ...styles,
        paddingLeft: '11px',
        fontSize: '12px',
        color: '#35150F',
        textAlign: 'left',
        padding: '1px 8px'
    }),
    indicatorsContainer: (prevStyle, state) => state.isMulti ? ({
        ...prevStyle,
        display: 'none'
    }) : null,
    clearIndicator: (styles) => ({
        ...styles,
        color: '#fff',
        borderRight: '2px solid hsl(148deg 46% 31%)',
        ':hover': {
            color: 'hsl(0deg 0% 100% / 80%)',
            cursor: 'pointer'
        }
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        width: '35px',
        height: '35px',
        color: '#fff',
        ':hover': {
            color: 'hsl(0deg 0% 100% / 80%)',
            cursor: 'pointer'
        }
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    singleValue: (styles) => ({
        ...styles,
        fontSize: '13px',
        color: '#35150F',
    }),
    option: (styles, {isFocused}) => ({
        borderWidth: '0',
        padding: '12px 16px 10px',
        fontSize: '13px',
        backgroundColor: isFocused ? '#35845A' : 'inherit',
        color: isFocused ? 'white' : '#35150F',
        ':hover': {
            backgroundColor: '#35845A',
            color: 'white',
            cursor: 'pointer'
        }
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#35150f85',
        fontSize: '13px'
    }),
    input: (styles) => ({
        ...styles,
        color: '#35150f85',
        fontSize: '13px'
    })
}